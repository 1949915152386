<template>
  <div class="capitalFlow bg-white p-10">
    <p class="common-tip-title mb-20 mt-10">充值记录</p>
     <div style="padding: 16px 30px;">
      <el-row :gutter="20">
        <el-col :span="3">
          <div class="grid-content bg-purple">
            <el-select v-model="payType" placeholder="请选择充值方式" size="small">
              <el-option
                v-for="item in options2"
                :key="item.rechargeWay"
                :label="item.label"
                :value="item.rechargeWay">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content bg-purple">
            <el-select v-model="topUpState" placeholder="请选择充值状态" size="small">
              <el-option
                v-for="item in options1"
                :key="item.status"
                :label="item.label"
                :value="item.status">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5.5">
          <div class="grid-content bg-purple">
            <el-date-picker
              v-model="value1"
              :clearable="false"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="创建结束日期"
              range-separator="至"
              size="small"
              start-placeholder="创建开始日期"
              type="daterange"
              unlink-panels
              @change="onCreatedChange">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="5.5">
          <div class="grid-content bg-purple">
            <el-date-picker
              v-model="value2"
              :clearable="false"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="更新结束日期"
              range-separator="至"
              size="small"
              start-placeholder="更新开始日期"
              type="daterange"
              unlink-panels
              @change="onUpdateChange">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content bg-purple">
            <el-input v-model="inputValue" placeholder="请输入单号" size="small"></el-input>
          </div>
        </el-col>
        <el-col :span="2.5">
          <div class="grid-content bg-purple">
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </div>
        </el-col>
        <el-col :span="2.5">
          <div class="grid-content bg-purple">
            <el-button size="small" @click="onReset">重置</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container">
      <div class="left"></div>
      <div class="right">
        <div class="right-wrapper">
          <el-table
            :data="tableData"
            border
            height="400"
            style="width: 100%">
            <el-table-column
              align="center"
              label="序号"
              prop="xu_hao"
              width="80">
            </el-table-column>
            <el-table-column
              align="center"
              label="单号"
              prop="dealNumber">
            </el-table-column>
            <el-table-column
              align="center"
              label="充值方式"
              prop="topUpType">
              <template slot-scope="scope">
                {{ topUpTypeText[scope.row.topUpType] }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="充值金额"
              prop="topUpAmount">
              <template slot-scope="scope">
                {{ '￥'+scope.row.topUpAmount.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="费率"
              prop="rates">
            </el-table-column>
            <el-table-column
              align="center"
              label="服务费"
              prop="serviceCharge">
            </el-table-column>
            <el-table-column
              align="center"
              label="充值状态"
              prop="topUpState">
              <template slot-scope="scope">
            <span :style="{color: scope.row.topUpState == '0' ? '#a9a908' : scope.row.topUpState == '1' ? '#409eff' : 'red'}">
              {{ topUpStateText[scope.row.topUpState] }}
            </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建时间"
              prop="createTime"
              width="170">
            </el-table-column>
            <el-table-column
              align="center"
              label="更新时间"
              prop="updateTime"
              width="170">
            </el-table-column>
          </el-table>
          <div class="feye">
            <el-pagination
              :current-page="page.current"
              :page-size="page.size"
              :page-sizes="[10, 20, 30]"
              :total="page.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {pickerOptions} from '@/views/User/FinancialManagement/dateCollation'
import {
  Rechargeage
} from '@/api/user/index'
import {dateFormat} from "@/utils/utils";

export default {
  name: 'RechargeIndex',
  data() {
    return {
      // 分页
      currentPage: 4,
      // 表格数据
      tableData: [],
      // 充值方式
      options2: [{
        rechargeWay: 0,
        label: '微信'
      }, {
        rechargeWay: 1,
        label: '支付宝'
      }, {
        rechargeWay: 2,
        label: '其它'
      }, {
        rechargeWay: 3,
        label: '线下'
      }],
      // 充值方式
      payType: '',
      // 充值状态
      options1: [{
        status: 0,
        label: '审核中'
      }, {
        status: 1,
        label: '审核通过'
      }, {
        status: 2,
        label: '审核未通过'
      }],
      // 充值状态
      topUpState: '',
      // 业务单号
      inputValue: '',
      // 标签页类型
      type: '',
      // 时间选择值
      value1: null,  // 创建日期
      value2: null,  // 更新日期
      // 时间带快捷选项
      pickerOptions: pickerOptions,
      page:{
        current:1,
        size:10,
        total:0
      },
      obj: {
        startTime: null,
        endTime: null,
        createOrUpdate: null
      }
    }
  },
  created() {
    this.getList()
  },
  computed:{
      //  充值状态
      topUpStateText(){
        return {
          0:'审核中',
          1:'审核通过',
          2:'审核未通过'
        }
      },
      // 充值类型
      topUpTypeText(){
        return {
          0:'微信',
          1:'支付宝',
          2:'其他',
          3:'线下'
        }
      }
    },
  methods: {
    // 创建日期
    onCreatedChange(e) {
      console.log('创建日期', e)
      this.obj.createOrUpdate = 1   // 创建
      let starts = dateFormat(e[0])
      let ends = dateFormat(e[1])
      if (1 === this.obj.createOrUpdate) {
        this.value2 = null
        this.obj.startTime = starts.split(' ')[0],
        this.obj.endTime = ends.split(' ')[0]
      }
      console.log('值',this.value1)
    },
    // 更新日期
    onUpdateChange(e) {
      console.log('更新日期', e)
      this.obj.createOrUpdate = 2   // 更新
      let starts = dateFormat(e[0])
      let ends = dateFormat(e[1])
      if (2 === this.obj.createOrUpdate) {
        this.value1 = null
        this.obj.startTime = starts.split(' ')[0],
        this.obj.endTime = ends.split(' ')[0]
      }
    },
    getList(){
      Rechargeage({
        current: this.page.current,
        size: this.page.size,
        topUpType: this.payType == '0' ? this.payType : this.payType || null,
        dealNumber: this.inputValue || null,
        topUpState: this.topUpState == '0' ? this.topUpState : this.topUpState || null,
        createOrUpdate: this.obj.createOrUpdate,
        startTime: this.obj.startTime,
        endTime: this.obj.endTime
      }).then(res => {
        console.log('列表',res)
        this.tableData = res.data.records
        this.page.total = res.data.total
        this.tableData.forEach((item, index) => {
          item['xu_hao'] = index + 1
        })
      })
    },
    // 查询
    onSubmit() {
      this.getList()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.getList(this.page)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getList(this.page)
    },
    // 重置
    onReset() {
      this.payType = ''
      this.inputValue = ''
      this.topUpState = ''
      this.obj.createOrUpdate = null
      this.value2 = null
      this.value1 = null
      this.obj.startTime = null
      this.obj.endTime = null
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-col {
  margin-bottom: 20px!important;
}
.container {
  display: flex;
}
.left {
  width: 0px;
}
.right{
  flex: 1;
  position: relative;
}
.right-wrapper{
  width: 100%;
}
.feye {
  display: flex;
  justify-content: right;
  margin-top: 20px;
  text-align: right;
}
.table-content {
  margin-top: 16px;
  padding: 30px;
}
.tab-active, .tab-item {
  width: 86px;
  height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border-radius: 4px 4px 0 0;
  color: #666;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.tab-active {
  font-weight: 700;
  color: #2ba7ff;
  background: #fff;
  border: none;
}
.capitalFlow {
  .tab-box {
    display: flex;
  }
}
ul {
  padding: 0;
}
</style>
